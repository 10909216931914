import React, { Component } from 'react';
import ReactDOM from 'react-dom';

function disclaimerPdfOpen2() {
    console.log('success');
}
class Disclaimer extends Component {
    
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      replacedLinks: this.props.replacedLinks,
      disclaimer: this.props.disclaimer,
      disclaimerPdfOpen: this.props.pdfOpen
    };
  }
  componentDidMount() {
    this.replaceDisclaimer();
    
  }
  componentWillUnmount() {
    
  }
  disclaimerPdfOpen = (element) => {
    const pdfViewer = document.getElementById(document.getElementById(element).getAttribute('value'));
    if (pdfViewer.classList.contains('active')) {
    pdfViewer.classList.remove('active');
    } else {
    pdfViewer.classList.add('active');
    }
   }
    
  replaceDisclaimer = () => {
    this.state.replacedLinks.map((item, index) => (
        this.setState(
            {disclaimer: this.state.disclaimer.replace(item.replace, '<span class="disclaimerPDF" value="pdfViewerDL'+index+'" id="pdfViewerDL'+index+'button" onClick="javascript:(function() { const pdfViewer = document.getElementById(document.getElementById(`pdfViewerDL'+index+'button`).getAttribute(`value`)); if (pdfViewer.classList.contains(`active`)) { pdfViewer.classList.remove(`active`);} else {pdfViewer.classList.add(`active`);} })()">'+item.link_text+'</span>')}
          )
    ))
    return;
  }
  render() {
    const {disclaimer} = this.state;
    const {replacedLinks, disclaimerText, pdfOpen} = this.props
      return (
        <React.Fragment>
        <div className='fCol12 faqDisclaimer' dangerouslySetInnerHTML={{ __html: disclaimer}}/>
        </React.Fragment>
      )
  }
}

export default Disclaimer;